import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IBookingPaymentReport {
  clientName: string;
  orderRef: string;
  orderExternalId: string;
  customerName: string;
  customerDocument: string;
  feeHolder: string;
  sellerName: string;
  sellerDocument: string;
  itemQuantity: number;
  itemName: string;
  itemBilledAmount: string;
  itemFeeAmount: string;
  itemFeePercent: number;
  sellerCommissionAmount: string;
  customerCommissionAmount: string;
  itemTaxAmount: string;
  itemTaxPercent: number;
  acquirerName: string;
  transactionId: string;
  paymentId: string;
  nsu: string;
  tid: string;
  authorizationCode: string;
  authorizedAt: string;
  activityName: string;
  bookedDate: string;
  bookedStartTime: string;
  bookedEndTime: string;
  scheduleExperience: number;
  waveSide?: 'COMBO' | 'LEFT' | 'RIGHT';
}

export interface IBookingPaymentReportsList {
  items: IBookingPaymentReport[];
  total: number;
  pages: number;
}

interface IListBookingReportsProps {
  clientRef: string;
  authorizationDateEnd: Date;
  authorizationDateInit: Date;
  limit?: number;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'createdAt';
}

export const listBookingPaymentReportsService = async ({
  authorizationDateEnd,
  authorizationDateInit,
  clientRef,
  limit,
  order,
  page,
  sort,
}: IListBookingReportsProps): Promise<IBookingPaymentReportsList> => {
  const { data } = await jhsfBookingsApi.get<IBookingPaymentReportsList>(
    '/bookings/payments/reports',
    {
      params: {
        authorizationDateEnd,
        authorizationDateInit,
        clientRef,
        limit,
        order,
        page,
        sort,
      },
    },
  );

  return data;
};
