import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';
import { useAuth } from '../../hooks/auth';

export type ErrorMessage =
  | 'booking-no-vacancies-available'
  | 'guest-booking-no-vacancies-available'
  | 'lodger-booking-no-vacancies-available'
  | 'user-max-day-bookings'
  | 'user-guest-max-day-bookings';

enum FriendlyErrorMessage {
  'booking-no-vacancies-available' = 'Não há vagas disponíveis',
  'guest-booking-no-vacancies-available' = 'Não há vagas disponíveis para convidados',
  'lodger-booking-no-vacancies-available' = 'Não há vagas disponíveis para hóspedes',
  'user-max-day-bookings' = 'Esgotado limite diário de reservas do usuário',
  'user-guest-max-day-bookings' = 'Esgotado limite diário de reservas para convidados do usuário',
}

interface IBookingConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onQueue?: () => void;
  onForce: () => void;
  skipLimits: boolean;
  hideQueueButton?: boolean;
  errorMessage: ErrorMessage;
}

export const BookingConfirmationModal = ({
  isOpen,
  onClose,
  onQueue,
  onForce,
  hideQueueButton = false,
  skipLimits,
  errorMessage,
}: IBookingConfirmationModalProps): JSX.Element => {
  const { user: authenticatedUser } = useAuth();

  return (
    <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Confirmação de reserva
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text>{FriendlyErrorMessage[errorMessage]}</Text>

          {skipLimits && (
            <>
              <Text mt="4" color="red.500" fontSize="sm">
                Forçar essa reserva ignorará todos limites de vagas, tanto do
                evento quanto da categoria de usuário.
              </Text>

              <Text mt="4" color="red.500" fontSize="sm">
                Para confirmar clique novamente no botão forçar reserva.
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Cancelar
            </Button>

            {!hideQueueButton && (
              <Button colorScheme="green" onClick={onQueue}>
                Adicionar na fila
              </Button>
            )}

            {authenticatedUser.featureGroup.features.some((feature) =>
              ['BOOKING_FULL_ACCESS', 'BOOKING_FORCE_LIMITS_ACCESS'].includes(
                feature.key,
              ),
            ) && (
              <Button
                colorScheme="orange"
                onClick={onForce}
                opacity={skipLimits ? 0.4 : 1}
              >
                Forçar reserva
              </Button>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
