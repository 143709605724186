import {
  IActivityItemBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface ICreateBookingProps {
  activityScheduleId: string;
  bookedDate: Date;
  bookingQueueId?: string;
  description?: string;
  endTime?: Date;
  isActive?: boolean;
  isVerified?: boolean;
  items?: IActivityItemBase[];
  notificationTimers?: number[];
  scheduleItems?: IActivityScheduleItemBase[];
  notifyCustomer?: boolean;
  skipLimits?: boolean;
  startTime?: Date;
  paymentType?: string;
  userId: string;
}

export type INewBooking = IBookingBase;

export const createBookingsService = async ({
  activityScheduleId,
  bookedDate,
  bookingQueueId,
  description,
  endTime,
  isActive,
  isVerified,
  items,
  notificationTimers,
  scheduleItems,
  notifyCustomer,
  skipLimits,
  startTime,
  paymentType,
  userId,
}: ICreateBookingProps): Promise<INewBooking> => {
  const { data } = await jhsfBookingsApi.post<INewBooking>(
    '/bookings',
    {
      activityScheduleId,
      bookedDate,
      bookingQueueId,
      description,
      endTime,
      isActive,
      items,
      notificationTimers,
      scheduleItems,
      notifyCustomer,
      startTime,
      paymentType,
      userId,
    },
    { params: { isVerified, skipLimits } },
  );

  return data;
};
