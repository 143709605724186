import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FieldError } from 'react-hook-form';

interface ISwitchProps extends ChakraSwitchProps {
  name: string;
  label?: string;
  error?: FieldError;
  inline?: boolean;
}

const SwitchBase: ForwardRefRenderFunction<HTMLInputElement, ISwitchProps> = (
  { name, label, error = null, inline = false, ...rest },
  ref,
) => (
  <FormControl isInvalid={!!error}>
    {inline ? (
      <HStack alignItems="center">
        <ChakraSwitch ref={ref} name={name} id={name} {...rest} />

        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      </HStack>
    ) : (
      <>
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <ChakraSwitch ref={ref} name={name} id={name} {...rest} />
      </>
    )}

    {!!error && (
      <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
    )}
  </FormControl>
);

export const Switch = forwardRef(SwitchBase);
