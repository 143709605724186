import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICreateBookingReportsProps {
  clientRef: string;
  authorizationDateEnd: Date;
  authorizationDateInit: Date;
  limit?: number;
  order?: 'ASC' | 'DESC';
  page?: number;
  sort?: 'createdAt';
}

export const createBookingPaymentsReportFileService = async ({
  clientRef,
  authorizationDateEnd,
  authorizationDateInit,
  limit,
  order,
  page,
  sort,
}: ICreateBookingReportsProps): Promise<string> => {
  const { data } = await jhsfBookingsApi.post<Blob>(
    '/bookings/payments/reports/xlsx',
    {
      authorizationDateEnd,
      authorizationDateInit,
      clientRef,
    },
    {
      responseType: 'blob',
      params: {
        limit,
        order,
        page,
        sort,
      },
    },
  );

  const fileUrl = URL.createObjectURL(data);

  return fileUrl;
};
