import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  HStack,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { WaveSideEnum } from '../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../models/users';
import { IBookingPaymentReport } from '../../../../../../../services/Bookings/ListBookingPaymentReportsService';

export type IBookingPaymentsReportTableItem = IBookingPaymentReport;

interface IBookingPaymentsReportTableProps extends BoxProps {
  paymentReports: IBookingPaymentsReportTableItem[];
  currentPage?: number;
  onPageChange?: (page: number) => void;
  totalPages?: number;
}

export const BookingPaymentsReportTable = ({
  paymentReports,
  currentPage,
  onPageChange,
  totalPages,
  ...rest
}: IBookingPaymentsReportTableProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Atividade</Th>
          <Th textAlign="center">Data/horário da reserva</Th>
          <Th>Nível de experiência</Th>
          <Th>Lado da onda</Th>
          <Th>Comprador</Th>
          <Th>Loja</Th>
          <Th>Item</Th>
          <Th>Valor</Th>
          <Th>Autorizado em</Th>
          <Th textAlign="right" w="8" />
        </Tr>
      </Thead>

      <Tbody fontSize="sm">
        {paymentReports.map((paymentReport, index) => (
          <Tr key={String(index)}>
            <Td fontSize="sm">{paymentReport.activityName}</Td>

            <Td textAlign="center">
              <Text>{paymentReport.bookedDate}</Text>

              <HStack justifyContent="center" spacing="1">
                <Text>{`${paymentReport.bookedStartTime} -`}</Text>
                <Text>{paymentReport.bookedEndTime}</Text>
              </HStack>
            </Td>

            <Td>
              <Text>{UserExperience[paymentReport.scheduleExperience]}</Text>
            </Td>

            <Td>
              {paymentReport.waveSide
                ? WaveSideEnum[paymentReport.waveSide]
                : '-'}
            </Td>

            <Td>{paymentReport.customerName}</Td>

            <Td>{paymentReport.sellerName}</Td>

            <Td>{paymentReport.itemName}</Td>

            <Td>{paymentReport.itemBilledAmount}</Td>

            <Td>{paymentReport.authorizedAt}</Td>

            <Td textAlign="center">
              <LinkButton
                to={{
                  pathname: '/bookings/details',
                  state: {
                    bookingId: paymentReport.orderExternalId,
                  },
                }}
              >
                <Icon as={RiEyeLine} fontSize="16" />
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>

    {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    )}
  </Box>
);
