import {
  Box,
  Heading,
  Flex,
  ButtonGroup,
  Button,
  Link,
  useToast,
  Icon,
  HStack,
  Text,
  VStack,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RiDownload2Line, RiSearchLine } from 'react-icons/ri';
import * as Yup from 'yup';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { createBookingPaymentsReportFileService } from '../../../../../services/Bookings/CreateBookingPaymentsReportFileService';
import { listBookingPaymentReportsService } from '../../../../../services/Bookings/ListBookingPaymentReportsService';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  BookingPaymentsReportTable,
  IBookingPaymentsReportTableItem,
} from './components/BookingPaymentsReportTable';

interface ILoadBookingPaymentsReports {
  authorizationDateEnd: Date;
  authorizationDateInit: Date;
  page: number;
}

interface IExportInvoiceReportsFormData {
  authorizationDateEnd: Date;
  authorizationDateInit: Date;
}

const bookingPaymentsReportSchema = Yup.object().shape({
  authorizationDateEnd: Yup.date().nullable().required('Requerido'),
  authorizationDateInit: Yup.date()
    .max(Yup.ref('endDate'), 'Data de início deve ser anterior à data final')
    .nullable()
    .required('Requerido'),
});

export const BookingPaymentsReportList = (): JSX.Element => {
  const toast = useToast();

  const [bookingPaymentsReportList, setBookingPaymentsReportList] = useState<
    IBookingPaymentsReportTableItem[]
  >([]);
  const [downloadLinkUrl, setDownloadLinkUrl] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const maxDate = useMemo(() => new Date(), []);

  const { register, handleSubmit, formState, setValue, control, watch } =
    useForm({
      resolver: yupResolver(bookingPaymentsReportSchema),
    });

  const { errors } = formState;

  const dateEnd = watch('authorizationDateEnd');
  const dateInit = watch('authorizationDateInit');

  const loadBookingPaymentsReports = useCallback(
    async ({
      authorizationDateEnd,
      authorizationDateInit,
      page,
    }: ILoadBookingPaymentsReports) => {
      try {
        const { items: paymentReports, pages } =
          await listBookingPaymentReportsService({
            clientRef: 'BVV', // TODO: puxar ref do empreendimento do usuário logado
            authorizationDateEnd,
            authorizationDateInit,
            limit: 20,
            order: 'DESC',
            sort: 'createdAt',
            page,
          });

        setTotalPages(pages);
        setBookingPaymentsReportList(paymentReports);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              'Ocorreu um erro ao carregar os dados dos pagamentos, tente novamente',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handlePageChange = useCallback(
    async (page: number) => {
      setCurrentPage(page);

      await loadBookingPaymentsReports({
        authorizationDateEnd: dateEnd,
        authorizationDateInit: dateInit,
        page,
      });
    },
    [dateEnd, dateInit, loadBookingPaymentsReports],
  );

  useEffect(() => {
    setValue('endDate', maxDate);
  }, [maxDate, setValue]);

  useEffect(() => {
    if (downloadLinkUrl) {
      downloadLinkRef.current?.click();
    }
  }, [downloadLinkUrl]);

  const handleExportInvoiceReports: SubmitHandler<IExportInvoiceReportsFormData> =
    useCallback(
      async ({ authorizationDateEnd, authorizationDateInit }) => {
        try {
          const fileUrl = await createBookingPaymentsReportFileService({
            clientRef: 'BVV', // TODO: puxar ref do empreendimento do usuário logado
            authorizationDateEnd,
            authorizationDateInit,
            order: 'DESC',
            sort: 'createdAt',
          });

          setDownloadLinkUrl(fileUrl);

          toast({
            title: 'Exportado com sucesso',
            description: 'Relatório foi exportado corretamente',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao exportar',
              description:
                'Ocorreu um erro ao exportar o relatório, tente novamente',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [toast],
    );

  const handleList: SubmitHandler<IExportInvoiceReportsFormData> = useCallback(
    async ({ authorizationDateEnd, authorizationDateInit }) => {
      setCurrentPage(1);

      await loadBookingPaymentsReports({
        authorizationDateEnd,
        authorizationDateInit,
        page: 1,
      });
    },
    [loadBookingPaymentsReports],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Relatórios
          </Heading>
        </Flex>

        <Flex
          as="form"
          flexDirection="column"
          onSubmit={handleSubmit(handleList)}
        >
          <ButtonGroup alignSelf="flex-end">
            <Button colorScheme="blue" size="sm" type="submit">
              <HStack alignItems="center">
                <Icon as={RiSearchLine} fontSize="md" />
                <Text>Listar</Text>
              </HStack>
            </Button>

            <Link
              hidden
              download={`booking-payments-reports-${Date.now()}.xlsx`}
              ref={downloadLinkRef}
              href={downloadLinkUrl}
            >
              Download
            </Link>
            <Button
              colorScheme="green"
              size="sm"
              onClick={handleSubmit(handleExportInvoiceReports)}
            >
              <HStack>
                <Icon as={RiDownload2Line} fontSize="md" />
                <Text>Exportar</Text>
              </HStack>
            </Button>
          </ButtonGroup>

          <VStack spacing="8">
            <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
              <DatePicker
                label="Data de autorização do pagamento de"
                maxDate={maxDate}
                control={control}
                isClearable={false}
                error={errors.authorizationDateInit}
                {...register('authorizationDateInit')}
              />

              <DatePicker
                label="Data de autorização do pagamento até"
                maxDate={maxDate}
                control={control}
                isClearable={false}
                error={errors.authorizationDateEnd}
                {...register('authorizationDateEnd')}
              />
            </SimpleGrid>

            <Divider />
          </VStack>
        </Flex>

        <BookingPaymentsReportTable
          mt="4"
          paymentReports={bookingPaymentsReportList}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
